import React from "react";
import { Link } from "react-router-dom";
import AboutImage from "../../images/post.jpg";

const SellCarSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center shadow-md py-10 bg-white-100">
      <div className="bg-white p-8 rounded-lg  text-center w-11/12 md:w-7/12">
        <h1 className="text-4xl font-bold mb-6">Want to Sell Your Car?</h1>
        <p className="text-gray-700 text-lg mb-8">
          Selling your car has never been easier! Post an ad in just a few
          simple steps and connect with potential buyers quickly.
        </p>
        <Link to="/create-listing">
          <button className="bg-green-500 text-white font-semibold py-3 px-6 text-lg rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2">
            Post an Ad
          </button>
        </Link>
      </div>
      <div className="w-full md:w-1/4 mt-6 md:mt-0">
          <img
            src={AboutImage}
            alt="Car trading"
            className="w-full rounded-lg shadow-md"
          />
      </div>
    </div>
  );
};

export default SellCarSection;
