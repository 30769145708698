import React from "react";
import { Link } from "react-router-dom";
import AboutImage from "../../images/featured_seller.jpg";
import WhatsaAppIcon from "../../images/whatsapp.jpg";

const BeFeaturedSeller = () => {
  const whatsappURL = "https://wa.me/+923447846324";
  // Open WhatsApp URL
  const contact = () => window.open(whatsappURL, "_blank");
  return (
    <div className="flex flex-col md:flex-row items-center justify-center shadow-md py-10 bg-white-100">
      <div className="bg-white p-8 rounded-lg  text-center w-11/12 md:w-7/12">
        <h1 className="text-4xl font-bold mb-6">
          Want to be our Featured Seller?
        </h1>
        <p className="text-gray-700 text-lg mb-8">
          Register Your Bargain/Showroom with us and manage your listings and
          customers through us.
        </p>
        <div
          onClick={contact}
          className="bg-green-500 md:ml-[35%] md:w-[200px] flex flex-row  py-3 px-2  rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
        >
          <img
            src={WhatsaAppIcon}
            className="w-[40px] h-[40px]"
            alt="Whatsapp"
            srcset=""
          />{" "}
          <h2 className="text-white font-semibold mt-1 ml-1 text-2xl">
            Contact Us
          </h2>
        </div>
      </div>

      <div className="w-full md:w-1/4 mt-6 md:mt-0">
          <img
            src={AboutImage}
            alt="Car trading"
            className="w-full rounded-lg shadow-md"
          />
      </div>
    </div>
  );
};

export default BeFeaturedSeller;
