import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { db } from "../../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import { ScaleLoader } from "react-spinners";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const FeaturedSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const carsRef = collection(db, "Feature-Seller");
        const qSnap = await getDocs(carsRef);
        const carsArray = [];
        qSnap.forEach((doc) => {
          carsArray.push({ id: doc.id, ...doc.data() }); // Include the doc ID if needed
        });
        setSellers(carsArray);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  return (
    <>
      <div className="text-2xl md:text-5xl text-center my-10 text-gray-800">
        Featured Sellers
      </div>
      <Carousel responsive={responsive}>
        {loading ? (
          <ScaleLoader className="text-center" />
        ) : (
          sellers.map((seller) => {
            return (
              <div className="rounded-md border border-gray-300 w-[90%] md:w-[245px] mx-[5%] max-w-sm:rounded-lg shadow-lg bg-white overflow-hidden">
                <img
                  src={seller.thumbnail}
                  alt="Card Image"
                  className="w-full h-48 object-cover"
                />
                <div className="p-5">
                  <h2 className="text-xl text-center font-bold text-gray-800 mb-2">
                    {seller.sellerName}
                  </h2>
                  <p className="text-gray-600 text-center text-sm">
                    {seller.address}
                  </p>
                </div>
                <Link to={`/seller-profile/${seller.id}`}>
                  <Button
                    _hover={{
                      bg: "black", // Change background color to black on hover
                      color: "white", // Ensure the text color remains white (optional)
                    }}
                    color="white"
                    bgGradient="linear(to-r, #0a1f44, #ff4500)"
                    className="btnGrad w-full my-2 mx-[27%] md:mx-[20%] font-medium"
                  >
                    View Listings
                  </Button>
                </Link>
              </div>
            );
          })
        )}
      </Carousel>
      <Link to="/featured-sellers">
      <button className="bg-green-500 mt-5 ml-[35%] md:ml-[90%] text-white font-semibold py-1 px-3 text-lg rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2">
        See all
      </button>
      </Link>
      </>
  );
};

export default FeaturedSellers;
