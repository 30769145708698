import React from "react";
import { Box, Image, Button, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import HeroSection from "../components/herosection/HeroSection";
import forRentImg from "../images/for-rent.png";
import forSaleImg from "../images/for-sale.png";
import FeaturedSellers from "../components/featuredSellers/featuredSellers";
import FeaturedCars from "../components/featuredCars/featuredCars";
import FeaturedBikes from "../components/featuredBikes/featuredBikes";
import SellCarSection from "../components/sellCarSection/SellCarSection";
import AboutUsSection from "../components/aboutUs/AboutUs";
import AvailableCarBrands from "../components/carBrands/CarBrands";
import AnimatedGradientLine from "../components/animatedLine/AnimatedLine";
import SellImage from "../images/featured.jpg";
import CabImage from "../images/book.jpg";
import FeaturedSellerImage from "../images/featured_seller.jpg";
import BeFeaturedSeller from "../components/beFeaturedSeller/beFeaturedSeller";

const Home = () => {
  const property = {
    imageUrl: "",
    imageAlt: "Image",
  };
  return (
    <>
      <HeroSection />
      <SellCarSection />
      <BeFeaturedSeller />
      <Stack
        direction={["column", "row"]}
        spacing="35px"
        align="center"
        justify="center"
        mt="5rem"
      >
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image
            src={forSaleImg}
            className="h-[220px]"
            alt={property.imageAlt}
          />
          <Box p="2">
            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              noOfLines={1}
              className="mx-[7%] mb-3"
            >
              Cars For Sale
            </Box>
            <Link to="/category/sale">
              <Button
                bg="#0a1f44"
                color="white"
                className="btnGrad my-1 mx-[7%]  font-medium"
                _hover={{
                  bg: "black", // Change background color to black on hover
                  color: "white", // Ensure the text color remains white (optional)
                }}
              >
                Explore
              </Button>
            </Link>
          </Box>
        </Box>
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image
            src={forRentImg}
            className="h-[220px]"
            alt={property.imageAlt}
          />
          <Box p="2">
            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              noOfLines={1}
              className="mx-[7%] mb-3"
            >
              Book A Ride
            </Box>

            <Link to="/category/rent">
              <Button
                bg="#ff4500"
                color="white"
                className="btnGrad my-1 mx-[7%]  font-medium"
                _hover={{
                  bg: "black", // Change background color to black on hover
                  color: "white", // Ensure the text color remains white (optional)
                }}
              >
                Explore
              </Button>
            </Link>
          </Box>
        </Box>
      </Stack>
      <AnimatedGradientLine />
      <FeaturedSellers />
      <AnimatedGradientLine />
      <div className="my-[5%]">
        <FeaturedCars />
      </div>
      <AnimatedGradientLine />
      <FeaturedBikes />
      <AnimatedGradientLine />
      <AboutUsSection />
      <section id="services" class="bg-white py-12">
        <div class="container mx-auto px-6">
          <h1 className="text-4xl text-center font-bold mb-6">Our Services</h1>
          <div class="grid md:grid-cols-3 gap-6 mt-6">
            <div class="bg-gray-100 p-6 rounded-lg shadow-md">
              <img
                src={FeaturedSellerImage}
                alt="Car trading"
                className="w-full h-[250px] rounded-lg mb-5 shadow-md"
              />

              <h4 class="text-lg font-semibold">Seller Listings Management</h4>
              <p class="text-gray-600 mt-2">
                Be our Featured Seller and have our back in managing everything
                for you from creating your store, uploading and managing your
                listings to handling customers for you.
              </p>
            </div>
            <div class="bg-gray-100 p-6 rounded-lg shadow-md">
              <img
                src={SellImage}
                alt="Car trading"
                className="w-full h-[250px] rounded-lg mb-5 shadow-md"
              />

              <h4 class="text-lg font-semibold">Sell Your Car with Us</h4>
              <p class="text-gray-600 mt-2">
                Have our back to list your car on our front page and on top of
                other cars listings. This will help customers to find you easily
                and help you sell your car within days of listing.
              </p>
            </div>
            <div class="bg-gray-100 p-6 rounded-lg shadow-md">
              <img
                src={CabImage}
                alt="Car trading"
                className="w-full h-[250px] rounded-lg mb-5 shadow-md"
              />

              <h4 class="text-lg font-semibold">List Your Cab for Rides</h4>
              <p class="text-gray-600 mt-2">
                Enhance your business in creating more opportunities to find
                more customers by using NCPWheels. Help yourself and your
                customers to find each other more easily.
              </p>
            </div>
          </div>
        </div>
      </section>
      <AvailableCarBrands />
      {/* <AdSenseAd client="ca-pub-8548134289451448" slot="2500951548" /> */}
    </>
  );
};

export default Home;
