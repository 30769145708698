import React, { useEffect, useState } from "react";

import "react-multi-carousel/lib/styles.css";
import { Button } from "@chakra-ui/react";
import { db } from "../firebase.config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import FadeSpinner from "../components/feedback/FadeSpinner";

const SeeAllFeaturedCars = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      try {
        const carsQuery = query(
          collection(db, "cars"),
          where("featured", "==", true)
        );
        const carQuerySnapshot = await getDocs(carsQuery);
        const carListings = carQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort cars by ranking based on sortOrder state
        const sortedCars = carListings.sort((a, b) =>
          sortOrder === "asc" ? a.ranking - b.ranking : b.ranking - a.ranking
        );
        setCars(sortedCars);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching car listings:", err);
        setError("Failed to fetch car listings.");
        setLoading(false);
      }
    };

    fetchCars();
  }, [sortOrder]);

  const checkDetails = (id) => {
    console.log("check details clicked!!");
    navigate(`/category/sale/${id}`);
  };

  return (
    <>
      <div className="min-h-screen bg-gray-100 p-6">
        <h1 className="text-4xl text-center mt-5 mb-10 text-gray-800 truncate">
          All Featured Cars
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {loading ? (
          <FadeSpinner />
        ) : cars.length == 0 ? (
          <h1>No cars</h1>
        ) : (
          cars.map((car) => {
            return (
              <div className="rounded-md border border-gray-300 w-full md:w-[245px] md:mx-[5%] max-w-sm:rounded-lg shadow-lg bg-white overflow-hidden">
                <img
                  src={car.carImages[0]}
                  alt="Card Image"
                  className="w-full h-48 object-cover"
                />
                <div className="p-5">
                  <h2 className="text-xl text-center font-bold text-gray-800 mb-2">
                    {car.modelName} {car.modelYear}
                  </h2>
                  <p className="text-gray-600 text-center text-sm">
                    {car.location}
                  </p>
                </div>
                <Button
                  onClick={() => checkDetails(car.id)}
                  color="white"
                  bgGradient="linear(to-r, #0a1f44, #ff4500)"
                  className="btnGrad my-1 mx-[17%]  font-medium"
                  _hover={{
                    bg: "black", // Change background color to black on hover
                    color: "white", // Ensure the text color remains white (optional)
                  }}
                >
                  View Details
                </Button>
              </div>
            );
          })
        )}
      </div>
      </div>
    </>
  );
};

export default SeeAllFeaturedCars;
