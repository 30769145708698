import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { Button } from "@chakra-ui/react";
import {
  getDoc,
  doc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FadeLoader } from "react-spinners";
import { Helmet } from "react-helmet";

const SellerProfile = () => {
  const [seller, setSeller] = useState(null);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [newRanking, setNewRanking] = useState("");
  const [currentListingId, setCurrentListingId] = useState(null);
  const [currentListing, setCurrentListing] = useState(null);
  const { sellerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellerProfileAndListings = async () => {
      setLoading(true);
      try {
        const sellerDocRef = doc(db, "Feature-Seller", sellerId);
        const sellerDocSnap = await getDoc(sellerDocRef);

        if (sellerDocSnap.exists()) {
          setSeller(sellerDocSnap.data());
        } else {
          setError("No such seller!");
        }

        const listingsRef = collection(
          db,
          "Feature-Seller",
          sellerId,
          "listings"
        );
        const listingsSnapshot = await getDocs(listingsRef);
        const listingsList = listingsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        listingsList.sort((a, b) => a.ranking - b.ranking);

        setListings(listingsList);
      } catch (error) {
        setError("Error fetching seller profile or listings: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSellerProfileAndListings();
  }, [sellerId]);

  const handleRedirectToAddListing = () => {
    navigate(`/addFeaturedSellerListingForm/${sellerId}`);
  };

  const handleUpdateRanking = (listingId, currentRanking) => {
    setCurrentListingId(listingId);
    setCurrentListing(listings.find((listing) => listing.id === listingId));
    setNewRanking(currentRanking);
    setModalVisible(true); // Show the modal when "Update Ranking" is clicked
  };

  const handleConfirmUpdateRanking = async () => {
    if (!newRanking || isNaN(newRanking) || newRanking <= 0) {
      toast.error("Please enter a valid ranking.");
      return;
    }

    try {
      const listingDocRef = doc(
        db,
        "Feature-Seller",
        sellerId,
        "listings",
        currentListingId
      );
      await updateDoc(listingDocRef, { ranking: Number(newRanking) });
      toast.success("Ranking updated!");
      setModalVisible(false); // Close the modal after updating
      setNewRanking(""); // Clear the ranking input
      window.location.reload(); // Refresh the page to reflect changes
    } catch (error) {
      toast.error("Failed to update ranking: " + error.message);
    }
  };
  const checkDetails = (id) => {
    navigate(`/category/sale/${id}`, {
      state: { type: "featured-seller", sellerId: sellerId },
    });
  };
  const handleSendToWhatsApp = async (listing) => {
    const formattedPhone = seller.phoneNumber.replace(/\D/g, ""); // Remove any non-numeric characters

    // Fetch image URL directly from Firestore or Firebase Storage (imageUrl is stored in Firestore)
    const imageUrl =
      listing.imageUrl || "https://example.com/default-image.jpg"; // Fallback image URL if none exists
    const encodedImageUrl = encodeURIComponent(imageUrl); // Properly encode the image URL

    // Construct the message with the image URL
    const message = `*Manufacturer:* ${listing.manufacturer}\n*Model:* ${
      listing.modelName
    }\n*Price:* Rs ${listing.price}\n*Discounted Price:* Rs ${
      listing.discountedPrice || "N/A"
    }\n*Mileage:* ${listing.mileage} km\n*City:* ${listing.city}\n*Location:* ${
      listing.location
    }\n*Notes:* ${listing.notes || "N/A"}\n*Ranking:* ${
      listing.ranking
    }\n\n*Images*: ${
      listing.images[0] || "No image available"
    }\n\nI am interested in this car. Can you provide more details about it?`;

    // Create the WhatsApp URL with the message
    const whatsappURL = `https://wa.me/${formattedPhone}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappURL, "_blank");
  };

  if (loading) return <FadeLoader className="ml-[45%] mt-[10%]" />;
  if (error) return <p>{error}</p>;

  return (
    <div className="min-h-screen bg-gray-100 p-6">
        <Helmet>
        <title>
          {`${seller.sellerName}'s listings | NCPWheels`}
        </title>
        <meta
          name="description"
          content={`Find the best deals on ${seller.sellerName}'s profile for sale.`}
        />
      </Helmet>
      <h1 className="text-4xl text-center my-1  text-gray-800 truncate">
        {seller.sellerName}'s Listings
      </h1>
      <div className="text-xl text-center mt-5 mb-10 text-gray-800 truncate">
        Phone Number: {seller.phoneNumber}
      </div>
      {/* Add Listing Button */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {loading ? (
          <FadeLoader />
        ) : listings.length == 0 ? (
          <h1 className="text-center">No listings found</h1>
        ) : (
          listings.map((car) => (
            <div
              key={car.id}
              className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
            >
              <img
                src={car.carImages[0]}
                alt={car.modelName}
                className="w-full h-40 object-cover"
              />
              <div className="p-4">
                <h2 className="text-xl font-semibold text-center text-gray-800">
                  {car.modelName}
                </h2>
                <p className="text-gray-800 text-center text-md">
                  PKR {car.price}
                </p>
                <Button
                  color="white"
                  onClick={() => checkDetails(car.id)}
                  bgGradient="linear(to-r, #0a1f44, #ff4500)"
                  className="btnGrad mt-3 mx-[20%] font-medium"
                  _hover={{
                    bg: "black", // Change background color to black on hover
                    color: "white", // Ensure the text color remains white (optional)
                  }}
                >
                  View Details
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SellerProfile;
