export const cities = [
  {
    id: '1',
    city: 'Swat'
  },
  {
    id: '2',
    city: 'Malakand'
  },
  {
    id: '3',
    city: 'Dir'
  },
  {
    id: '4',
    city: 'Buner'
  },
  {
    id: '5',
    city: 'Shangla'
  },
  {
    id: '6',
    city: 'Gilgit Baltistan'
  },
  {
    id: '7',
    city: 'Chitral'
  },
  {
    id: '8',
    city: 'Ex FATA'
  },
  {
    id: '9',
    city: 'Chaman'
  },
  {
    id: '10',
    city: 'Balochistan'
  },
  {
    id: '11',
    city: 'Kohistan'
  },
]
