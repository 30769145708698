import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const [carModel, setCarModel] = useState("");
  const [city, setCity] = useState("");
  const [purpose, setPurpose] = useState("");
  const [saleType, setSaleType] = useState("");

  const navigate = useNavigate();

  const params = {
    carModel: carModel,
    city: city,
    purpose: purpose,
    saleType: saleType
  }

  const clickButton = () => {
    if(carModel === "") {
      alert("Car Model is necessary.");
    }
    else if(purpose === ""){
      alert("Purpose is necessary");
    }
    else if(purpose === "sale" && saleType === "") {
      alert("Sale type is necessary.")
    }
    else if( purpose === "sale" && saleType === "bargains") {
      navigate("/bargains-listings", {state: params});
    } 
    else if (purpose == "sale" && saleType === "home") {
      navigate("/category/sale", {state: params});
    }
    else {
      navigate("/category/rent", {state: params});
    }
  };

  return (
    <div style={styles.heroContainer}>
      <h1 style={styles.heading}>Find NCP Cars in your area</h1>
      <h2 style={styles.heading2}>Your dream car is just one click away!!</h2>
      <div className="flex flex-col justify-center items-center gap-2 w-full max-w-[90%] md:flex-row md:gap-2 md:max-w-[75%]">
        <input
          className="w-full"
          type="text"
          placeholder="Car Model Name"
          style={styles.inputField}
          value={carModel}
          onChange={(e) => {
            setCarModel(e.target.value);
          }}
        />
        <select
          className="w-full"
          style={styles.inputField}
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          <option value="allCities">All Cities</option>
          <option value="swat">Swat</option>
          <option value="dir">Dir</option>
          <option value="buner">Buner</option>
          <option value="shangla">Shangla</option>
          <option value="gilgit">Gilgit Baltistan</option>
          <option value="Balochistan">Balochistan</option>
          <option value="fata">Ex FATA</option>
          <option value="chitral">Chitral</option>
          <option value="kohistan">Kohistan</option>
        </select>
        <select
          className="w-full"
          style={styles.inputField}
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
        >
          <option value="">Select Purpose</option>
          <option value="sale">Sale</option>
          <option value="rent">Book Ride</option>
        </select>
        <select
          className="w-full"
          style={styles.inputField}
          value={saleType}
          onChange={(e) => setSaleType(e.target.value)}
        >
          <option value="">Select Seller Type</option>
          <option value="home">Home Used Cars</option>
          <option value="bargains">Bargains</option>
        </select>
        <button onClick={clickButton} style={styles.searchButton}>
          Search
        </button>
      </div>
    </div>
  );
};

const styles = {
  heroContainer: {
    background: "linear-gradient(90deg, #0a1f44, #ff4500)",
    // Dark shiny blue
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    padding: "10px",
    textAlign: "center",
  },
  heading: {
    fontSize: "2.5rem",
    marginBottom: "2px",
  },
  heading2: {
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    width: "100%",
    maxWidth: "75%",
  },
  inputField: {
    flex: 1,
    padding: "10px",
    fontSize: "1rem",
    border: "1px solid #ccc",
    borderRadius: "5px",
    outline: "none",
    color: "gray",
  },
  searchButton: {
    padding: "10px 20px",
    backgroundColor: "#25D366",
    color: "white",
    fontSize: "1rem",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    borderWidth: 2,
    borderColor: "indigo",
  },
};

export default HeroSection;
