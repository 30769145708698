export const carsData = [
    {
        id: '1',
        manufacturer: 'Honda',
    },
    {
        id: '2',
        manufacturer: 'Toyota',
    },
    {
        id: '3',
        manufacturer: 'Hyndai',
    },
    {
        id: '4',
        manufacturer: 'Suzuki',
    },
    {
        id: '5',
        manufacturer: 'Audi',
    },
    {
        id: '6',
        manufacturer: 'Diahatsu',
    },
    {
        id: '7',
        manufacturer: 'Range Rover',
    },
    {
        id: '8',
        manufacturer: 'Kia',
    },
    {
        id: '9',
        manufacturer: 'Mercedes',
    },
    {
        id: '10',
        manufacturer: 'Nissan',
    },
    {
        id: '11',
        manufacturer: 'MG',
    },
    {
        id: '12',
        manufacturer: 'Wolks Wagen',
    },
    {
        id: '13',
        manufacturer: 'Changan',
    },
    {
        id: '14',
        manufacturer: 'BMW',
    },
    {
        id: '15',
        manufacturer: 'Chevrolet',
    },
    {
        id: '16',
        manufacturer: 'Peugeot',
    },
    {
        id: '17',
        manufacturer: 'Jeep',
    },
    {
        id: '18',
        manufacturer: 'Mazda',
    },
    {
        id: '19',
        manufacturer: 'Mitsubishi',
    },
    {
        id: '20',
        manufacturer: 'Other',
    },
    
]
    
