import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase.config";// Adjust the import based on your Firebase config

const useFetchFeaturedListings = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllFeaturedListings = async () => {
      setLoading(true);
      try {
        const sellersRef = collection(db, "Feature-Seller");
        const sellersSnapshot = await getDocs(sellersRef);

        let allListings = [];

        for (const sellerDoc of sellersSnapshot.docs) {
          const sellerId = sellerDoc.id; // Get the seller's document ID

          const listingsRef = collection(db, "Feature-Seller", sellerId, "listings");
          const listingsSnapshot = await getDocs(listingsRef);

          const sellerListings = listingsSnapshot.docs.map((doc) => ({
            id: doc.id,
            sellerId, // Add sellerId to each listing
            ...doc.data(),
          }));

          allListings = [...allListings, ...sellerListings];
        }

        // Sort listings by ranking (if it exists)
        setListings(allListings);
      } catch (error) {
        setError("Error fetching listings: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllFeaturedListings();
  }, []);

  return { listings, loading, error };
};

export default useFetchFeaturedListings;
