import React, { useEffect, useState } from "react";
import { Stack, Box, Button } from "@chakra-ui/react";
import MenuItem from "./MenuItem";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import UserProfile from "./UserProfile";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import HomeIcon from "../../images/home.png";
import FeedIcon from "../../images/feed.png";

const MenuLinks = ({ isOpen, onClose }) => {
  const [loginState, setLoginState] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoginState(true);
      } else {
        setLoginState(false);
      }
    });
  }, []);

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
      bg={{ base: "white", md: "transparent" }} // Add background for mobile menu
      p={{ base: 4, md: 0 }} // Add padding for better mobile layout
      boxShadow={{ base: "md", md: "none" }} // Add shadow for mobile menu
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem closeMenu={onClose} to="/feed"><img className="w-[20px] h-[25px]" src={FeedIcon} /></MenuItem>
        <MenuItem closeMenu={onClose}to="/"><img className="w-[20px] h-[20px]" src={HomeIcon} /></MenuItem>
        <MenuItem closeMenu={onClose}to="/category/sale">Sale</MenuItem>
        <MenuItem closeMenu={onClose}to="/category/rent">Book A Ride</MenuItem>
        <MenuItem closeMenu={onClose}to="/profile">Profile</MenuItem>
        <MenuItem closeMenu={onClose}to="/create-listing">
          <AiOutlineAppstoreAdd color="indigo" size="1.7rem" />
        </MenuItem>
        {!loginState && (
          <MenuItem closeMenu={onClose}to="/sign-in">
            <Button variant="outline" colorScheme="blue" size="md">
              Sign In
            </Button>
          </MenuItem>
        )}
        {!loginState && (
          <MenuItem closeMenu={onClose}to="/sign-up">
            <Button
              bgGradient="linear(to-r, #0a1f44, #ff4500)"
              color="white"
              size="md"
              _hover={{
                bg: "black", // Change background color to black on hover
                color: "white", // Ensure the text color remains white (optional)
              }}
            >
              Sign Up
            </Button>
          </MenuItem>
        )}
        {loginState && <UserProfile />}
      </Stack>
    </Box>
  );
};

export default MenuLinks;
