import React from "react";
import { Box, Image } from "@chakra-ui/react";
import LogoImg from "../../images/logo.png";
import { Link } from "react-router-dom";

const Logo = (props) => {
  return (
    <Link to={"/"}>
      <Box {...props}>
        <Image src={LogoImg} />
      </Box>
    </Link>
  );
};

export default Logo;
