import React, { useState } from "react";
import { Box, Button, Flex, IconButton } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import MenuLinks from "./MenuLinks";
import Logo from "./Logo";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () =>  {
    setIsOpen(false);
   }  

  return (
    <Box bg="white.600" color="white" px={4}>
      <Flex align="center" justify="space-between" wrap="wrap" py={4}>
        <Box fontSize="2xl" fontWeight="bold">
          <Logo className="w-[200px]" />
        </Box>
        <IconButton
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          display={{ base: "block", md: "none" }}
          onClick={toggleMenu}
          aria-label="Toggle Menu"
          variant="outline"
          color="black"
        />
        <MenuLinks isOpen={isOpen} onClose={toggleMenu} />
      </Flex>
    </Box>
  );
};

export default NavBar;
