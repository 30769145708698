import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
       <Helmet>
        <title>
          Privacy Policy | NCPWheels
        </title>
        <meta
          name="description"
          content={`Find out what we have to offer for you.`}
        />
      </Helmet>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700 mb-4">
        Welcome to NCPWheels! Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform.
      </p>

      <h2 className="text-xl font-semibold mt-6">1. Information We Collect</h2>
      <p className="text-gray-700 mb-4">
        We collect information that you provide directly to us, including your name, email, phone number, and car details when you list, buy, or sell a vehicle. We also collect data related to ride bookings and social interactions on our platform.
      </p>

      <h2 className="text-xl font-semibold mt-6">2. How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">
        We use your information to facilitate transactions, enhance user experience, process ride bookings, and enable communication between buyers and sellers. Additionally, we may use data for analytics, security, and improving our services.
      </p>

      <h2 className="text-xl font-semibold mt-6">3. Sharing of Information</h2>
      <p className="text-gray-700 mb-4">
        We do not sell your personal data. However, we may share information with service providers, law enforcement, or as required by law to ensure a safe trading environment.
      </p>

      <h2 className="text-xl font-semibold mt-6">4. Security of Your Information</h2>
      <p className="text-gray-700 mb-4">
        We implement security measures to protect your data. However, no system is completely secure, so please use strong passwords and be cautious when sharing personal details online.
      </p>

      <h2 className="text-xl font-semibold mt-6">5. Your Choices</h2>
      <p className="text-gray-700 mb-4">
        You can update your profile information, manage privacy settings, and request data deletion by contacting us. Opt-out options for marketing communications are also available.
      </p>

      <h2 className="text-xl font-semibold mt-6">6. Changes to This Policy</h2>
      <p className="text-gray-700 mb-4">
        We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated revision date.
      </p>

      <h2 className="text-xl font-semibold mt-6">7. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about this Privacy Policy, please contact us at <span className="font-semibold">support@ncpwheels.com</span>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
