import React from 'react'
import { FadeLoader } from 'react-spinners'


const FadeSpinner = () => {
  return (
    <div className="flex justify-center	justify-items-center items-center">
      <FadeLoader  loading={true} color='purple' />
    </div>
  )
}

export default FadeSpinner