import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@chakra-ui/react";
import { db } from "../firebase.config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import FadeSpinner from "../components/feedback/FadeSpinner";

const SeeAllFeaturedBikes = () => {
  const [bikes, setBikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBikes = async () => {
      try {
        setLoading(true);
        const carsRef = collection(db, "featured-bikes");
        const qSnap = await getDocs(carsRef);
        const bikesArray = [];
        qSnap.forEach((doc) => {
          bikesArray.push({ id: doc.id, ...doc.data() });
        });
        setBikes(bikesArray);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Failed to load featured bikes.");
      }
    };

    fetchBikes();
  }, []);

  const checkDetails = (id) => {
    navigate(`/category/sale/${id}`, { state: { type: "featured-bike" } });
  };

  return (
    <>
      <div className="min-h-screen bg-gray-100 p-6">
        <h1 className="text-4xl text-center mt-5 mb-10 text-gray-800 truncate">
          All Featured Bikes
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {loading ? (
            <FadeSpinner />
          ) : bikes.length === 0 ? (
            <h1>No featured bikes</h1>
          ) : (
            bikes.map((bike) => (
              <div
                key={bike.id}
                className="rounded-md border border-gray-300 w-full md:w-[245px] md:mx-[5%] max-w-sm:rounded-lg shadow-lg bg-white overflow-hidden"
              >
                <img
                  src={bike.carImages?.[0] || "placeholder-image.jpg"}
                  alt="Bike Image"
                  className="w-full h-48 object-cover"
                />
                <div className="p-5">
                  <h2 className="text-xl text-center font-bold text-gray-800 mb-2">
                    {bike.modelName}
                  </h2>
                  <p className="text-gray-600 text-center text-sm">
                    {bike.location}
                  </p>
                </div>
                <Button
                  onClick={() => checkDetails(bike.id)}
                  bgGradient="linear(to-r, #0a1f44, #ff4500)"
                  color="white"
                  className="my-1 px-[20%] mx-[25%] font-medium"
                  _hover={{
                    bg: "black", // Change background color to black on hover
                    color: "white", // Ensure the text color remains white (optional)
                  }}
                >
                  View Details
                </Button>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default SeeAllFeaturedBikes;
