import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

const ListingItemMobile = ({ id, car }) => {
  return (
    <>
      <div
        key={id}
        className="bg-white w-[90%] rounded-lg my-5 shadow-md overflow-hidden border border-gray-200 truncate"
      >
        <img
          src={car.carImages[0]}
          alt={car.modelName}
          className="w-full h-40 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-semibold text-center text-gray-800">
            {car.modelName}
          </h2>
          <p className="text-gray-800 text-center text-md">PKR {car.price}</p>
          <Link to={`/category/${car.type}/${id}`}>
          <Button
            color="white"
            bgGradient="linear(to-r, #0a1f44, #ff4500)"
            className="btnGrad mt-3 mx-[20%] font-medium"
            _hover={{
              bg: "black", // Change background color to black on hover
              color: "white", // Ensure the text color remains white (optional)
            }}
          >
            View Details
          </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ListingItemMobile;
