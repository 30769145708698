import React, { useState } from 'react';
import { cities } from '../../data/citiesData';

const Sidebar = ({ carModel, city, setCarModel, setCity }) => {

  return (
    <div className="w-64 h-auto bg-gray-100 text-white px-4 py-[50px] flex flex-col rounded-md border border-gray-300 mx-5">
      <h2 className="text-xl text-gray-800 font-bold mb-6">Selected Filters</h2>

      {/* Car Model Section */}
      <div className="mb-4">
        <h3 className="text-lg text-gray-800 font-semibold mb-2">Car Model</h3>
        <div
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => setCarModel(e.target.textContent)}
          className="w-full px-3 py-2 bg-white text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {carModel}
        </div>
      </div>

      {/* City Section */}
      <div className="mb-4">
        <h3 className="text-lg text-gray-800 font-semibold mb-2">City</h3>
        <select
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className="w-full px-3 py-2 bg-white text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {cities.map((cityOption, index) => (
            <option key={cityOption.id} value={cityOption.city}>
              {cityOption.city}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Sidebar;
