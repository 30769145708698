import React, { useEffect, useState } from "react";
import ListingItem from "../components/listings/ListingItem";
import { Link, useParams } from "react-router-dom";
import { db } from "../firebase.config";
import {
  collection,
  getDocs,
  where,
  query,
  limit,
  startAfter,
} from "firebase/firestore";
import ScaleSpinner from "../components/feedback/ScaleSpinner";
import { Heading, Button } from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/SideBar";
import ListingItemMobile from "../components/listings/ListingItemMobile";
import WhatsaAppIcon from "../images/whatsapp.jpg";
import { Helmet } from "react-helmet";

const Listings = () => {
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const whatsappURL = "https://wa.me/+923447846324";
  // Open WhatsApp URL
  const contact = () => window.open(whatsappURL, "_blank");

  const params = useParams();
  const location = useLocation();
  const param = location.state;
  const [carModel, setCarModel] = useState(param ? param.carModel : "");
  const [city, setCity] = useState(param ? param.city : "");
  const [priceRange, setPriceRange] = useState("");

  const ITEMS_PER_PAGE = 10;

  function isPartialMatch(target, search) {
    const targetLower = target.toLowerCase();
    const searchLower = search.toLowerCase();

    let matches = 0;
    for (let char of searchLower) {
      if (targetLower.includes(char)) {
        matches++;
      }
    }

    // If more than 70% of the characters match
    return matches / searchLower.length >= 0.7;
  }

  // Fetch cars with filters applied
  const fetchCars = async (isNextPage = false) => {
    try {
      setLoading(true);

      // Fetch regular cars
      const carsRef = collection(db, "cars");
      let q;

      // Determine if filters are applied
      const isFilterApplied =
        carModel !== "" || city !== "" || priceRange !== "";

      if (isFilterApplied) {
        // Fetch all cars matching the filters with no limit
        setHasMore(false);
        q = query(carsRef, where("type", "==", params.categoryName));
      } else {
        // Base query for regular cars with limit for pagination
        q = query(
          carsRef,
          where("type", "==", params.categoryName),
          limit(ITEMS_PER_PAGE)
        );

        // Add pagination if fetching the next page
        if (isNextPage && lastVisible) {
          q = query(
            carsRef,
            where("type", "==", params.categoryName),
            startAfter(lastVisible),
            limit(ITEMS_PER_PAGE)
          );
        }
      }

      const qSnap = await getDocs(q);
      const carsArray = [];

      // Apply filters manually if needed
      qSnap.forEach((doc) => {
        const carData = doc.data();

        if (
          (carModel === "" ||
            isPartialMatch(carData.modelName || "", carModel) ||
            isPartialMatch(carData.manufacturer || "", carModel)) &&
          (city === "" || carData.city?.toLowerCase() === city.toLowerCase()) &&
          (priceRange === "" || parseInt(carData.price) <= parseInt(priceRange))
        ) {
          carsArray.push({
            id: doc.id,
            data: { ...carData, isFeatured: carData.featured || false },
          });
        }
      });

      // Sort cars to ensure featured cars appear at the top
      carsArray.sort((a, b) => b.data.isFeatured - a.data.isFeatured);

      // Update the `hasMore` flag only for paginated requests (no filters)
      if (!isFilterApplied) {
        if (qSnap.docs.length < ITEMS_PER_PAGE) {
          setHasMore(false);
        }
        setLastVisible(qSnap.docs[qSnap.docs.length - 1]);
      }

      // Update the state with the fetched cars
      setCars((prev) => (isNextPage ? [...prev, ...carsArray] : carsArray));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cars:", error);
      setLoading(false);
    }
  };

  // Trigger fetchCars whenever filters change
  useEffect(() => {
    fetchCars(false);
  }, [carModel, city, priceRange]);

  useEffect(() => {
    fetchCars();
  }, [params.categoryName]);

  return (
    <div className="flex flex-col max-w-full overflow-hidden container px-3 truncate">
      <Helmet>
        <title>
          {param ? `${carModel} for ${params.categoryName} | NCPWheels` : "Search Cars"}
        </title>
        <meta
          name="description"
          content={`Find the best deals on ${carModel} cars for sale.`}
        />
      </Helmet>
      {params.categoryName === "rent" ? (
        <div className="flex flex-col md:flex-row items-center justify-center bg-white shadow-md">
          <h1 className="text md:text-2xl text-center my-5 text-gray-800 truncate">
            Want us book a ride for you?
          </h1>
          <div
            onClick={contact}
            className="bg-green-500 flex flex-row ml-2 py-1 px-5  rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
          >
            <img
              src={WhatsaAppIcon}
              className="w-[40px] h-[40px] ml-2"
              alt="Whatsapp"
              srcset=""
            />{" "}
            <h2 className="text-white font-semibold mt-1 ml-1 text-xl">
              Contact
            </h2>
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row items-center justify-center bg-white shadow-md">
          <h1 className="text md:text-2xl text-center my-5 text-gray-800 truncate">
            Want to buy straight away from Bargains?
          </h1>
          <Link to="/featured-sellers">
            <Button
              mx={4}
              my={1}
              colorScheme="teal"
              onClick={() => fetchCars(true)}
              isLoading={loading}
            >
              Explore
            </Button>
          </Link>
        </div>
      )}
      <h1 className="text-3xl md:text-5xl text-center my-10 text-gray-800 truncate">
        {params.categoryName === "rent" ? "Available Rides" : "Cars For Sale"}
      </h1>

      <div className="flex flex-row">
        <div className="hidden md:block">
          <Sidebar
            carModel={carModel}
            city={city}
            priceRange={priceRange}
            setCarModel={setCarModel}
            setCity={setCity}
            setPriceRange={setPriceRange}
          />
        </div>
        <div>
          {loading && cars.length === 0 ? (
            <ScaleSpinner />
          ) : cars && cars.length > 0 ? (
            <>
              {React.Children.toArray(
                cars.map((car) => {
                  return (
                    <div>
                      <div className="hidden md:block lg:block">
                        <ListingItem id={car.id} data={car.data} />
                      </div>
                      <div className="block md:hidden lg:hidden w-screen">
                        <ListingItemMobile id={car.id} car={car.data} />
                      </div>
                    </div>
                  );
                })
              )}
              {hasMore && (
                <Button
                  mt={4}
                  colorScheme="teal"
                  onClick={() => fetchCars(true)}
                  isLoading={loading}
                >
                  Load More
                </Button>
              )}
            </>
          ) : (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Search not found!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Sorry, the item you're trying to search is not found.
              </AlertDescription>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default Listings;
