import React from 'react';
import Honda from '../../images/CarBrands/honda.png';
import Toyota from '../../images/CarBrands/toyota.png';
import Diahatsu from '../../images/CarBrands/diahatsu.png';
import BMW from '../../images/CarBrands/bmw.jpg';
import Mercedes from '../../images/CarBrands/mercedes.png';
import Audi from '../../images/CarBrands/audi.png';
import Suzuki from '../../images/CarBrands/suzuki.jpg';
import Chevroltet from '../../images/CarBrands/chevrolet.jpg';
import Hyundai from '../../images/CarBrands/hyundai.jpg';
import Mg from '../../images/CarBrands/mg.png';
import Peugeot from '../../images/CarBrands/peugeot.png';
import Nissan from '../../images/CarBrands/nissan.png';

const AvailableCarBrands = () => {
  const brands = [
    { name: 'Toyota', logo: Toyota },
    { name: 'Honda', logo: Honda },
    { name: 'Diahatsu', logo: Diahatsu },
    { name: 'BMW', logo: BMW },
    { name: 'Mercedes', logo: Mercedes },
    { name: 'Audi', logo: Audi },
    { name: 'Suzuki', logo: Suzuki },
    { name: 'Chevrolet', logo: Chevroltet },
    { name: 'Hyundai', logo: Hyundai },
    { name: 'MG', logo: Mg },
    { name: 'Peugeot', logo: Peugeot },
    { name: 'Nissan', logo: Nissan }
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <h1 className="text-5xl font-bold text-center mb-12">Available Car Brands</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 justify-items-center">
        {brands.map((brand, index) => (
          <div key={index} className="flex flex-col items-center">
            <img 
              src={brand.logo} 
              alt={brand.name} 
              className="w-20 h-20 object-contain mb-4 rounded-md"
            />
            <p className="text-lg font-semibold text-gray-700">{brand.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailableCarBrands;
