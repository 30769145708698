import React, { useState } from "react";
import FadeSpinner from "../components/feedback/FadeSpinner";
import useFetchFeaturedListings from "../components/useFetchFeaturedListings/useFetchFeaturedListings";
import ListingItem from "../components/listings/ListingItem";
import { useParams } from "react-router-dom";
import Sidebar from "../components/sidebar/SideBar";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const BargainsListings = () => {
  const { listings, loading, sellerId, error } = useFetchFeaturedListings();
  const params = useParams();
  const location = useLocation();
  const param = location.state;

  const [carModel, setCarModel] = useState(param.carModel);
  const [priceRange, setPriceRange] = useState("");

  const ITEMS_PER_PAGE = 10;

  function isPartialMatch(target, search) {
    if (!target || !search) return false;

    const targetLower = target.toLowerCase();
    const searchLower = search.toLowerCase();

    let matches = 0;
    for (let char of searchLower) {
      if (targetLower.includes(char)) {
        matches++;
      }
    }

    // If more than 70% of the characters match
    return matches / searchLower.length >= 0.7;
  }

  // Filter listings based on user input
  const filteredListings = listings.filter((listing) => {
    const matchesCarModel = carModel
      ? isPartialMatch(listing.modelName, carModel)
      : true;
    const matchesPrice =
      priceRange && listing.price
        ? parseInt(listing.price) >= parseInt(priceRange.split("-")[0]) &&
          parseInt(listing.price) <= parseInt(priceRange.split("-")[1])
        : true;

    return matchesCarModel && matchesPrice;
  });

  if (loading) return <FadeSpinner />;
  if (error) return <p>{error}</p>;

  return (
    <>
      <Helmet>
            <title>
              {param ? `${carModel} for sale in Bargains | NCPWheels` : "Search Cars"}
            </title>
            <meta
              name="description"
              content={`Find the best deals on ${carModel} cars for sale.`}
            />
          </Helmet>
      <div className="flex flex-row items-center gap-4 py-4 px-10 bg-white shadow-md rounded-lg mb-10">
        <input
          type="text"
          placeholder="Search Car Model"
          value={carModel}
          onChange={(e) => setCarModel(e.target.value)}
          className="w-full sm:w-1/2 sm:mx-[24%] p-3 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
      </div>

      {filteredListings.map((listing) => (
        <ListingItem data={listing} sellerId={sellerId} id={listing.id} key={listing.id} />
      ))}
    </>
  );
};

export default BargainsListings;
