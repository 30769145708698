// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDNpWBgptfrxMKI_6r3D2za3mlbHMI_CEg",
  authDomain: "ncpwheels-463b6.firebaseapp.com",
  projectId: "ncpwheels-463b6",
  storageBucket: "ncpwheels-463b6.firebasestorage.app",
  messagingSenderId: "97548367655",
  appId: "1:97548367655:web:008c301193702685f2db02",
  measurementId: "G-NCNPRW9Z45"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);