import { Link } from "react-router-dom";
import {
  Button,
  Heading,
  Text,
  Flex,
  Box,
  Image,
  Stack,
  Badge,
} from "@chakra-ui/react";
// icons
import { ImLocation } from "react-icons/im";
import { FaBluetooth, FaCarCrash } from "react-icons/fa";
import { GiCarSeat } from "react-icons/gi";
import { AiTwotoneDelete } from "react-icons/ai";
import { EditIcon } from "@chakra-ui/icons";

import moment from "moment";
import "./ListingItem.css";

const ListingItem = ({ data, id, sellerId, deleteListing, editListing }) => {
  return (
    <div className="flex flex-col mx-1 md:flex-row p-6 gap-4 w-full shadow-xl mb-8 sm:w-10/12 sm:mx-auto border border-gray-300 rounded-md shadow-white sm:shadow-slate-200">
      <div className="w-[350px] relative">
        <Image
          borderRadius={20}
          className="w-full object-cover h-[90%] absolute"
          src={data.carImages[0]}
          alt=""
        />
        {data.offer && (
          <Badge colorScheme="red" className="absolute right-0">
            offer
          </Badge>
        )}
        {data.featured && (
          <Badge colorScheme="red" className="absolute right-0">
            featured
          </Badge>
        )}
      </div>
      <div className="basis-1/2 relative">
        <Heading className="mb-3" as="h4" size="md">
          {data.modelName && data.modelName} {data.modelYear}
        </Heading>
        <Heading className="mb-3" as="h5" size="sm">
          {data.manufacturer}
        </Heading>
        <Text fontSize="md p-4">
          <ImLocation className="inline mr-2" />
          {data.city}
        </Text>

        <Stack direction={["column", "row"]} spacing="15px" className="mt-1">
          <Box className="mr-3">
            <Text
              fontSize="md p-4"
              as={data.options && data.options.leatherSeats ? "" : "s"}
            >
              <GiCarSeat className="inline mr-2" />
              {data.options && data.options.leatherSeats
                ? "Leather Seats"
                : "Leather Seats"}
            </Text>
          </Box>
          <Box className="mr-3">
            <Text as={data.options && data.options.bluetooth ? "" : "s"}>
              <FaBluetooth className="inline mr-2" />
              {data.options && data.options.bluetooth
                ? "Bluetooh"
                : "Bluetooth"}
            </Text>
          </Box>
          <Box className="mr-3">
            <Text
              fontSize="md p-4"
              as={data.options && data.options.airBags ? "" : "s"}
            >
              <FaCarCrash className="inline mr-2" />
              {data.options && data.options.airBags ? "Airbags" : "Airbags"}
            </Text>
          </Box>
        </Stack>

        <Flex className="mt-2">
          <Box className="mr-3">
            <Text fontSize="md p-4">
              <span className="font-bold"> Mileage: </span>
              {data.mileage} Km
            </Text>
          </Box>

          <Box className="mr-3">
            <Text fontSize="md p-4">
              <span className="font-bold">Color: </span>
              {data.color}
            </Text>
          </Box>
        </Flex>

        <Text className="mt-2" fontSize="lg">
          <span className="font-bold">Model: </span> {data.modelYear}{" "}
          {data.modelYear > 2019 && (
            <span className="font-bold text-red-600	text-sm">New!</span>
          )}
        </Text>
        <Badge className="absolute top-0 right-0" colorScheme="green">
          {data?.timestamp ? moment(data?.timestamp?.toDate()).fromNow() : ""}
        </Badge>
        <hr />
      </div>

      <div className="basis-1/4 relative">
        <Box>
          <Text fontSize="2xl">{data.type === "rent" ? "Rent" : "Price"}</Text>
          <Text fontSize="2xl" as={data.offer && "s"} className="font-bold">
            {data.price} PKR
          </Text>
          <span className="text-sm mx-1">
            {data.type === "rent" ? "/Ride" : "Total"}
          </span>
          {data.offer && (
            <Text className="text-orange-500	font-bold	">
              {data.discountedPrice} PKR
            </Text>
          )}
        </Box>

        <Stack direction={["column", "column"]}>
          <Link
            to={
              data?.type
                ? `/category/${data.type}/${id}`
                : `/category/sale/${id}`
            }
            {...(!data?.type && { state: { type: "featured-seller-listing", sellerId: data.sellerId, listingId: id } })}
          >
            <Button
              color="white"
              bgGradient="linear(to-r, #0a1f44, #ff4500)"
              className="btnGrad mt-3 mx-1 font-medium"
              _hover={{
                bg: "black", // Change background color to black on hover
                color: "white", // Ensure the text color remains white (optional)
              }}
            >
              View Details
            </Button>
          </Link>

          {editListing && (
            <Link to={`/edit-listing/${id}`}>
              <Button
                leftIcon={<EditIcon />}
                style={{ width: "150px" }}
                colorScheme="orange"
                variant="outline"
                className="mt-2 mx-1"
              >
                Edit
              </Button>
            </Link>
          )}
        </Stack>

        {deleteListing && (
          <AiTwotoneDelete
            onClick={() => deleteListing(data.id)}
            cursor="pointer"
            size={25}
            className="absolute top-0 right-0"
          />
        )}
      </div>
    </div>
  );
};

export default ListingItem;
