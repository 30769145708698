import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { db } from "../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import { ScaleLoader } from "react-spinners";

const SeeAllFeaturedSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const carsRef = collection(db, "Feature-Seller");
        const qSnap = await getDocs(carsRef);
        const sellersArray = [];
        qSnap.forEach((doc) => {
          sellersArray.push({ id: doc.id, ...doc.data() }); // Include the doc ID if needed
        });
        setSellers(sellersArray);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  return (
    <>
      <div className="min-h-screen bg-gray-100 p-6">
        <h1 className="text-4xl text-center mt-5 mb-10 text-gray-800 truncate">
          All Featured Sellers
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {loading ? (
            <ScaleLoader className="text-center" />
          ) : (
            sellers.map((seller) => {
              return (
                <div className="rounded-md border border-gray-300 w-full md:w-[245px] md:mx-[5%] max-w-sm:rounded-lg shadow-lg bg-white overflow-hidden">
                  <img
                    src={seller.thumbnail}
                    alt="Card Image"
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-5">
                    <h2 className="text-xl text-center font-bold text-gray-800 mb-2">
                      {seller.sellerName}
                    </h2>
                    <p className="text-gray-600 text-center text-sm">
                      {seller.address}
                    </p>
                  </div>
                  <Link to={`/seller-profile/${seller.id}`}>
                    <Button
                      _hover={{
                        bg: "black", // Change background color to black on hover
                        color: "white", // Ensure the text color remains white (optional)
                      }}
                      color="white"
                      bgGradient="linear(to-r, #0a1f44, #ff4500)"
                      className="btnGrad my-1 mx-[17%] font-medium"
                    >
                      View Listings
                    </Button>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default SeeAllFeaturedSellers;
