import { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { AiFillCheckCircle } from "react-icons/ai";
import { db } from "../firebase.config";
import ScaleSpinner from "../components/feedback/ScaleSpinner";
import { Helmet } from "react-helmet";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};

const ListingDetails = () => {
  const [carListing, setCarListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const location = useLocation();
  const param = location.state;

  useEffect(() => {
    const fetchCarListing = async () => {
      try {
        let sellerId = param?.sellerId?.trim();
        let docRef;

        if (param) {
          if (param.type === "featured-bike") {
            docRef = doc(db, "featured-bikes", params.listingId);
          } else if (
            ["featured-seller", "featured-seller-listing"].includes(param.type)
          ) {
            docRef = doc(
              db,
              "Feature-Seller",
              sellerId,
              "listings",
              params.listingId
            );
          }
        } else {
          docRef = doc(db, "cars", params.listingId);
        }

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCarListing(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching listing:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCarListing();
  }, [params.listingId]);

  if (loading) {
    return (
      <ScaleSpinner className="flex justify-center items-center h-screen" />
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b to-white overflow-hidden ">
         <Helmet>
            <title>
              {carListing ? `${carListing.modelName} Details | NCPWheels` : "Car Details"}
            </title>
            <meta
              name="description"
              content={`Find details for ${carListing.modelName}.`}
            />
          </Helmet>
      {/* Image Carousel */}
      <div className="relative h-[300px] sm:h-[500px] overflow-hidden ">
        <Carousel responsive={responsive} infinite className="h-full shadow-xl">
          {carListing.carImages.map((url, index) => (
            <div key={index} className="relative w-full h-full">
              <img
                src={url}
                alt="Car"
                className="w-full h-full object-cover transition-all duration-500 hover:scale-105 transform lg:w-[60%] lg:ml-[300px]"
              />
            </div>
          ))}
        </Carousel>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl px-4 py-8 mx-auto space-y-8 overflow-hidden">
        {/* Title Section */}
        <div className="text-center">
          <h1 className="text-3xl sm:text-5xl font-bold text-indigo-900">
            {carListing.manufacturer}
          </h1>
          <h2 className="text-xl sm:text-3xl font-semibold text-red-600">
            {carListing.modelName}
          </h2>
          <div className="flex justify-center space-x-4 mt-2">
            <span className="px-3 py-1 text-white bg-red-500 rounded-full">
              {carListing.modelYear}
            </span>
            <span className="px-3 py-1 text-white bg-indigo-600 rounded-full">
              {carListing.transmission}
            </span>
          </div>
        </div>

        {/* Details Grid */}
        <div className="grid gap-6 md:grid-cols-2">
          {/* Specifications */}
          <div className="p-6 bg-white rounded-2xl shadow-lg">
            <h3 className="mb-4 text-xl font-bold text-indigo-900 border-b-2 border-red-500">
              Vehicle Details
            </h3>
            <div className="space-y-4">
              {["Color", "Mileage", "Price", "City", "Location"].map(
                (key, index) => (
                  <div
                    key={index}
                    className="flex items-center p-3 bg-gray-50 rounded-lg"
                  >
                    <AiFillCheckCircle className="text-red-500 text-xl" />
                    <span className="ml-3 font-semibold text-indigo-800">
                      {key}:
                    </span>
                    <span className="ml-2 text-gray-600">
                      {carListing[key.toLowerCase()]}
                    </span>
                  </div>
                )
              )}
                <div
                    className="flex items-center p-3 bg-gray-50 rounded-lg"
                  >
              <AiFillCheckCircle className="text-red-500 text-xl" />
              <span className="ml-3 font-semibold text-indigo-800">
                Contact:
              </span>
              <span className="ml-2 text-gray-600">
                {carListing.phoneNumber}
              </span>
            </div>
            </div>
          </div>

          {/* Additional Info */}
          <div className="space-y-6">
            {carListing.notes && (
              <div className="p-6 bg-white rounded-2xl shadow-lg">
                <h3 className="mb-4 text-xl font-bold text-indigo-900 border-b-2 border-red-500">
                  Seller's Notes
                </h3>
                <p className="text-gray-600">{carListing.notes}</p>
              </div>
            )}

            {carListing.offer && (
              <div className="p-6 bg-red-100 rounded-2xl shadow-lg">
                <h3 className="text-xl font-bold text-red-600">
                  Special Offer!
                </h3>
                <div className="flex items-center space-x-4 mt-2">
                  <span className="line-through text-gray-500">
                    {carListing.price} PKR
                  </span>
                  <span className="text-2xl font-bold text-indigo-900">
                    {carListing.discountedPrice} PKR
                  </span>
                </div>
                <div className="mt-2 text-sm text-red-500">
                  Limited time offer!
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetails;
