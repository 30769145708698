import React from "react";
import "./animatedLine.css"; // Import custom CSS for animation

const AnimatedGradientLine = () => {
  return (
    <div className="w-full my-2 h-[2px] bg-gradient-to-r from-indigo-500 to-red-500 animated-gradient-line"></div>
  );
};

export default AnimatedGradientLine;
