import React from "react";

import { Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({ to, closeMenu, children, ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <Link
      to={to}
      onClick={closeMenu}
      className={`px-3 py-1 rounded-md font-medium ${isActive ? "bg-blue-300 text-indigo-950" : "text-gray-800"}`}
      display="block"
    >
      <Text>{children}</Text>
    </Link>
  );
};

export default MenuItem;
