import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  useColorModeValue,
  Heading,
  Image,
  Button,
} from "@chakra-ui/react";
import {
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaFacebook,
  FaTiktok,
} from "react-icons/fa";
import MainLogo from "../images/main-icon.png";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer style={{ marginTop: "170px" }}>
      <Box
        bg={useColorModeValue("gray.50", "gray.900")}
        color={useColorModeValue("gray.700", "gray.200")}
      >
        <Container as={Stack} maxW={"6xl"} py={10}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
            <Stack spacing={6}>
              <Box>
                <Image width="60px" height="60px" src={MainLogo} />
              </Box>
              <Text fontSize={"sm"}>© 2025 NCPWheels. All rights reserved</Text>
              <Stack direction={"row"} spacing={6}>
                <a href="https://www.facebook.com/profile.php?id=61571865360641">
                  <FaFacebook />
                </a>
                <FaYoutube />
                <a href="https://www.tiktok.com/@ncpwheels">
                  <FaTiktok />
                </a>
              </Stack>
            </Stack>

            <Box>
              <Heading as="h5" size="sm" mb={8}>
                Support
              </Heading>
              <Stack align={"flex-start"}>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link href={"#"}>Report A Fake Advertisment</Link>
                <Link href={"#"}>Contact Us</Link>
              </Stack>
            </Box>

            <Box>
              <Heading as="h5" size="sm" mb={8}>
                NCPWheels
              </Heading>
              <Stack align={"flex-start"}>
                <Link to="/create-listing">Add An Ad</Link>
                <Link to="profile">Profile</Link>
                <Link to="/featured-sellers">Featured Sellers</Link>
              </Stack>
            </Box>

            <Box>
              <Heading as="h5" size="sm" mb={8}>
                About Us
              </Heading>
              <Stack align={"flex-start"}>
                <Text mb={3}>NCP Wheels by SN Limited</Text>
              </Stack>
            </Box>
          </SimpleGrid>
        </Container>

        <Box
          borderTopWidth={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
        >
          <Container
            as={Stack}
            maxW={"6xl"}
            py={4}
            direction={{ base: "column", md: "column" }}
            spacing={4}
            justify={{ md: "center" }}
            align={{ md: "center" }}
          >
            <Text>
              © {new Date().getFullYear()} NCP Wheels. All rights reserved. By{" "}
              <span className="text-red-700 font-bold	">
                <a>SeraNasir Limited</a>
              </span>
            </Text>
          </Container>
        </Box>
      </Box>
    </footer>
  );
}
