import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@chakra-ui/react";
import { db } from "../../firebase.config";
import { getDocs, collection } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import ScaleSpinner from "../feedback/ScaleSpinner";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const FeaturedBikes = () => {
  const [bikes, setBikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBikes = async () => {
      try {
        setLoading(true);
        const carsRef = collection(db, "featured-bikes");
        const qSnap = await getDocs(carsRef);
        const bikesArray = [];
        qSnap.forEach((doc) => {
          bikesArray.push({ id: doc.id, ...doc.data() });
        });
        setBikes(bikesArray);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Failed to load featured bikes.");
      }
    };

    fetchBikes();
  }, []);

  const checkDetails = (id) => {
    navigate(`/category/sale/${id}`, { state: { type: "featured-bike" } });
  };

  return (
    <>
      <div className="text-2xl md:text-5xl text-center my-10 text-gray-800">
        Featured Bikes For Sale
      </div>
      {error ? (
        <h1 className="text-red-500 text-center">{error}</h1>
      ) : (
        <Carousel responsive={responsive}>
          {loading ? (
            <ScaleSpinner />
          ) : bikes.length === 0 ? (
            <h1>No featured bikes</h1>
          ) : (
            bikes.map((bike) => (
              <div
                key={bike.id}
                className="rounded-md border border-gray-300 w-[90%] md:w-[245px] mx-[5%] max-w-sm:rounded-lg shadow-lg bg-white overflow-hidden"
              >
                <img
                  src={bike.carImages?.[0] || "placeholder-image.jpg"}
                  alt="Bike Image"
                  className="w-full h-48 object-cover"
                />
                <div className="p-5">
                  <h2 className="text-xl text-center font-bold text-gray-800 mb-2">
                    {bike.modelName}
                  </h2>
                  <p className="text-gray-800 text-center text-md">
                    PKR {bike.price}
                  </p>
                  <p className="text-gray-600 text-center text-sm">
                    {bike.location}
                  </p>
                </div>
                <Button
                  onClick={() => checkDetails(bike.id)}
                  bgGradient="linear(to-r, #0a1f44, #ff4500)"
                  color="white"
                  className="btnGrad w-full my-2 mx-[27%] md:mx-[20%] font-medium"
                  _hover={{
                    bg: "black", // Change background color to black on hover
                    color: "white", // Ensure the text color remains white (optional)
                  }}
                >
                  View Details
                </Button>
              </div>
            ))
          )}
        </Carousel>
      )}
      <Link to="/featured-bikes">
        <button className="bg-green-500 mt-5 ml-[35%] md:ml-[90%] text-white font-semibold py-1 px-3 text-lg rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2">
          See all
        </button>
      </Link>
    </>
  );
};

export default FeaturedBikes;
