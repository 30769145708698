import React, { useState, useEffect, useContext } from "react";
import { collection, query, where, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase.config"; // Import your Firestore config
import UserContext from "../../contexts/user/UserContext";// Import your UserContext
import FadeSpinner from "../feedback/FadeSpinner";

const UserPosts = () => {
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { displayName } = useContext(UserContext); // Get displayName from context

  useEffect(() => {
    if (!displayName) return;

    const fetchUserPosts = async () => {
      const postsRef = collection(db, "posts");
      const q = query(postsRef, where("author", "==", displayName));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const postsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserPosts(postsData);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchUserPosts();
  }, [displayName]);

  const handleDelete = async (postId) => {
    try {
      await deleteDoc(doc(db, "posts", postId));
      setUserPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post: ", error);
    }
  };

  if (loading) {
    return <FadeSpinner />;
  }

  if (userPosts.length === 0) {
    return <div className="mx-[35%] my-5">No posts found for {displayName}.</div>;
  }

  return (
    <div className="space-y-4">
      <div className="text-4xl text-center my-5  text-gray-800">Buyer Requests by Me</div>
      {userPosts.map((post) => (
        <div
          key={post.id}
          className="bg-white shadow-md rounded-lg p-4 border border-gray-200 mx-[10%]"
        >
          <p className="text-lg font-medium text-gray-800 mb-2">
            {post.description}
          </p>
          <div className="text-sm text-gray-600 space-y-1">
            <p>
              <span className="font-semibold">Location:</span> {post.location}
            </p>
            <p>
              <span className="font-semibold">Make:</span> {post.make}
            </p>
            <p>
              <span className="font-semibold">Model:</span> {post.model}
            </p>
            <p>
              <span className="font-semibold">City:</span> {post.city}
            </p>
            <p className="text-xs text-gray-500">
              Updated: {post.timestamp?.toDate?.().toLocaleString() || "Just now"}
            </p>
          </div>

          {/* Likes and Comments Count */}
          <div className="mt-4 flex justify-between text-sm text-gray-600">
            <span>{post.likes || 0} Likes</span>
            <span>{post.comments?.length || 0} Comments</span>
          </div>
          <button
            onClick={() => handleDelete(post.id)}
            className="mt-2 text-red-600 hover:underline text-sm"
          >
            Delete Post
          </button>
        </div>
      ))}
    </div>
  );
};

export default UserPosts;
