import React from "react";
import AboutImage from "../../images/about.jpg";

const AboutUsSection = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-wrap items-center justify-between w-full max-w-6xl p-8 bg-white rounded-lg shadow-md">
        <div className="w-full md:w-3/4 pr-4">
          <h1 className="text-5xl font-bold mb-6">Who We Are?</h1>
          <p className="text-gray-700 text-xl leading-relaxed">
            Welcome NCPWheelers! We make it simple and convenient for users to
            trade, buy, sell, and rent NCP cars. Whether you're looking for your
            next vehicle or want to post your car for sale, our platform
            connects you with a community of trusted users. Join us and explore
            a hassle-free way to manage your car needs.
          </p>
        </div>
        <div className="w-full md:w-1/4 mt-6 md:mt-0">
            <img
              src={AboutImage}
              alt="Car trading"
              className="w-full rounded-lg shadow-md"
            />
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
