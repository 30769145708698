export const colorsData = [
    {
        id: '1',
        color: 'Red'
    },
    {
        id: '2',
        color: 'White'
    },
    {
        id: '3',
        color: 'Brown'
    },
    {
        id: '4',
        color: 'Black'
    },
    {
        id: '5',
        color: 'Blue'
    },
    {
        id: '6',
        color: 'Grey'
    },
    {
        id: '7',
        color: 'Dark blue'
    },
    {
        id: '8',
        color: 'Orange'
    },
    {
        id: '9',
        color: 'Green'
    },
    {
        id: '10',
        color: 'Other'
    }
]