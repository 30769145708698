import React, { useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  getDoc,
  updateDoc,
  doc,
  serverTimestamp,
  orderBy,
  query,
  onSnapshot,
  increment,
  arrayRemove,
  arrayUnion,
  where,
} from "firebase/firestore";
import { db, storage, auth } from "../firebase.config"; // Import your Firestore config
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Button } from "@chakra-ui/react";
import UserContext from "../contexts/user/UserContext";
import { useContext } from "react";
import { Avatar, Text } from "@chakra-ui/react";
import FadeSpinner from "../components/feedback/FadeSpinner";
import { Helmet } from "react-helmet";

const BuyerRequestsFeed = () => {
  const { displayName, dispatch } = useContext(UserContext);

  const isMounted = useRef(true);
  const navigate = useNavigate();
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [commentInput, setCommentInput] = useState({});
  const [uploading, setUploading] = useState(false);
  const [likedPosts, setLikedPosts] = useState({});
  const [likedComments, setLikedComments] = useState({});
  const [postLoading, setPostLoading] = useState(false);
  const [newPost, setNewPost] = useState({
    author: displayName,
    description: "",
    location: "",
    make: "",
    model: "",
    city: "",
    budget: "",
  });

  // UseEffect to fetch liked posts for the user
  useEffect(() => {
    if (!displayName) return;

    const fetchLikedPosts = async () => {
      const q = query(
        collection(db, "posts"),
        where("likedBy", "array-contains", displayName)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const likedPostIds = {};
        snapshot.docs.forEach((doc) => {
          likedPostIds[doc.id] = true;
        });
        setLikedPosts(likedPostIds);
      });

      return () => unsubscribe();
    };

    fetchLikedPosts();
  }, [displayName]);
  // Optional: Manage upload state
  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          navigate("/sign-in");
        }
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  useEffect(() => {
    if (!displayName) return;

    const fetchLikedComments = async () => {
      const unsubscribe = onSnapshot(collection(db, "posts"), (snapshot) => {
        const likedCommentIds = {};

        snapshot.docs.forEach((doc) => {
          const post = doc.data();
          const postId = doc.id;

          post.comments?.forEach((comment, index) => {
            if (comment.likedBy?.includes(displayName)) {
              likedCommentIds[`${postId}_${index}`] = true;
            }
          });
        });

        setLikedComments(likedCommentIds);
      });

      return () => unsubscribe();
    };

    fetchLikedComments();
  }, [displayName]);

  // Handle Image Upload
  const handleImageUpload = async (e, postId) => {
    if (!e.target.files[0]) return;

    const file = e.target.files[0];
    const storageRef = ref(storage, `commentImages/${postId}/${file.name}`);
    setUploading(true);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      setCommentInput((prev) => ({
        ...prev,
        [postId]: {
          ...prev[postId],
          imageUrl: downloadURL,
        },
      }));
      setUploading(false);
    } catch (error) {
      console.error("Error uploading image: ", error);
      setUploading(false);
    }
  };

  // Fetch posts from Firestore
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({ type: "SET_USER", payload: user.displayName });
      }
    });
    const postsRef = collection(db, "posts");
    const q = query(postsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedPosts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(updatedPosts); // Update the feed instantly
    });
    setLoading(false);

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <FadeSpinner />;
  }
  if (posts.length === 0) {
    return (
      <div className="mt-[10%]">
        <FadeSpinner />
      </div>
    );
  }
  const handleToggleLikeComment = async (postId, commentIndex) => {
    const postRef = doc(db, "posts", postId);
    const userId = displayName; // Assuming displayName uniquely identifies the user

    try {
      const postSnapshot = await getDoc(postRef);
      if (postSnapshot.exists()) {
        const post = postSnapshot.data();
        const comments = post.comments || [];
        const comment = comments[commentIndex];

        if (!comment) return;

        const isLiked = comment.likedBy?.includes(userId);

        // Update Firestore
        const updatedComments = [...comments];
        if (isLiked) {
          updatedComments[commentIndex] = {
            ...comment,
            likes: comment.likes - 1,
            likedBy: comment.likedBy.filter((id) => id !== userId),
          };
        } else {
          updatedComments[commentIndex] = {
            ...comment,
            likes: (comment.likes || 0) + 1,
            likedBy: [...(comment.likedBy || []), userId],
          };
        }

        await updateDoc(postRef, {
          comments: updatedComments,
        });

        // Update local state
        setLikedComments((prev) => ({
          ...prev,
          [`${postId}_${commentIndex}`]: !isLiked,
        }));
      }
    } catch (error) {
      console.error("Error toggling like for comment: ", error);
    }
  };

  const handleAddReply = async (postId, commentIndex, replyText) => {
    try {
      const postRef = doc(db, "posts", postId);
      const post = posts.find((post) => post.id === postId);
      const updatedComments = [...post.comments];
      const reply = {
        text: replyText,
        author: displayName,
        timestamp: new Date().toISOString(),
      };

      updatedComments[commentIndex].replies =
        updatedComments[commentIndex].replies || [];
      updatedComments[commentIndex].replies.push(reply);

      await updateDoc(postRef, {
        comments: updatedComments,
      });

      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId ? { ...post, comments: updatedComments } : post
        )
      );
    } catch (error) {
      console.error("Error adding reply: ", error);
    }
  };

  // Handle adding a new post
  const handleSavePost = async () => {
    setPostLoading(true);
    if (
      !newPost.description ||
      !newPost.location ||
      !newPost.make ||
      !newPost.model ||
      !newPost.city
    ) {
      alert("All fields are required!");
      return;
    }

    const postToSave = {
      ...newPost,
      author: displayName,
      likes: 0,
      comments: [],
      timestamp: serverTimestamp(),
    };

    try {
      const docRef = await addDoc(collection(db, "posts"), postToSave);
      setPosts([{ id: docRef.id, ...postToSave }, ...posts]); // Optimistic update
      setNewPost({
        author: displayName,
        description: "",
        location: "",
        make: "",
        model: "",
        city: "",
      });
      setModalOpen(false);
    } catch (error) {
      console.error("Error adding post: ", error);
    }
    setPostLoading(false);
  };

  const toggleCommentsVisibility = (postId) => {
    setPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? { ...post, showComments: !post.showComments }
          : post
      )
    );
  };
  const handleToggleLike = async (postId) => {
    const postRef = doc(db, "posts", postId);
    const userId = displayName; // Assuming displayName uniquely identifies the user

    try {
      const postSnapshot = await getDoc(postRef);
      if (postSnapshot.exists()) {
        const post = postSnapshot.data();
        const isLiked = post.likedBy?.includes(userId);

        if (isLiked) {
          // User is unliking the post
          await updateDoc(postRef, {
            likes: increment(-1),
            likedBy: arrayRemove(userId),
          });
        } else {
          // User is liking the post
          await updateDoc(postRef, {
            likes: increment(1),
            likedBy: arrayUnion(userId),
          });
        }

        // Update UI state
        setLikedPosts((prev) => ({
          ...prev,
          [postId]: !isLiked,
        }));
      }
    } catch (error) {
      console.error("Error toggling like: ", error);
    }
  };

  // Handle Add Comment
  const handleAddComment = async (postId) => {
    const commentData = commentInput[postId] || {};
    if (!commentData.text?.trim() && !commentData.imageUrl) {
      alert("Please add text or upload an image!");
      return;
    }

    const postRef = doc(db, "posts", postId);
    const updatedPost = posts.find((post) => post.id === postId);
    const updatedComments = [
      ...(updatedPost.comments || []),
      {
        author: displayName,
        text: commentData.text || "",
        imageUrl: commentData.imageUrl || null,
      },
    ];

    try {
      // Update Firestore
      await updateDoc(postRef, { comments: updatedComments });

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId ? { ...post, comments: updatedComments } : post
        )
      );

      // Clear input state for the post
      setCommentInput((prev) => ({
        ...prev,
        [postId]: { text: "", imageUrl: null },
      }));
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <Helmet>
        <title>
          Feed | NCPWheels
        </title>
        <meta
          name="description"
          content={`Post your requirements or find the best buyers for your car`}
        />
      </Helmet>
     <h1 className="text-3xl md:text-4xl text-center my-5 text-gray-800 truncate">Buyer Requests</h1>
      {/* Button to open the modal */}
      <div
        className="w-full md:w-auto md:mx-[15%] rounded-md mb-4"
        style={{ background: "linear-gradient(90deg, #0a1f44, #ff4500)" }}
      >
        <button
          onClick={() => setModalOpen(true)}
          className="w-auto md:w-[40%] ml-[12%] md:ml-[28%] my-1 bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-700"
        >
          Which car do you want to buy?
        </button>
      </div>

      {/* Modal for creating a new post */}
      {modalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          style={{ zIndex: 1400 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg  w-full md:w-[60%] max-h-screen overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">
              Create a New Buyer Request
            </h2>
            <div className="space-y-4">
              {/* Description */}
              <div>
                <label
                  htmlFor="description"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  placeholder="Add description... e.g., Looking for a second-hand sedan."
                  className="w-full p-2 border rounded-md mb-2"
                  rows={2}
                  value={newPost.description || ""}
                  onChange={(e) =>
                    setNewPost({ ...newPost, description: e.target.value })
                  }
                />
              </div>

              {/* Location */}
              <div>
                <label
                  htmlFor="location"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Location
                </label>
                <input
                  id="location"
                  type="text"
                  placeholder="e.g., Islamabad, Pakistan"
                  value={newPost.location}
                  onChange={(e) =>
                    setNewPost({ ...newPost, location: e.target.value })
                  }
                  className="w-full p-2 border rounded-md"
                />
              </div>

              {/* Make */}
              <div>
                <label
                  htmlFor="make"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Make
                </label>
                <input
                  id="make"
                  type="text"
                  placeholder="e.g., Toyota, Honda"
                  value={newPost.make}
                  onChange={(e) =>
                    setNewPost({ ...newPost, make: e.target.value })
                  }
                  className="w-full p-2 border rounded-md"
                />
              </div>

              {/* Model */}
              <div>
                <label
                  htmlFor="model"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Model
                </label>
                <input
                  id="model"
                  type="text"
                  placeholder="e.g., Corolla, Civic"
                  value={newPost.model}
                  onChange={(e) =>
                    setNewPost({ ...newPost, model: e.target.value })
                  }
                  className="w-full p-2 border rounded-md"
                />
              </div>

              {/* City */}
              <div>
                <label
                  htmlFor="city"
                  className="block text-gray-700 font-medium mb-1"
                >
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  placeholder="e.g., Lahore, Karachi"
                  value={newPost.city}
                  onChange={(e) =>
                    setNewPost({ ...newPost, city: e.target.value })
                  }
                  className="w-full p-2 border rounded-md"
                />
              </div>

              {/* Budget */}
              <div>
                <label
                  htmlFor="budget"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Budget
                </label>
                <input
                  id="budget"
                  type="text"
                  placeholder="e.g., 1,000,000 PKR"
                  value={newPost.budget || ""}
                  onChange={(e) =>
                    setNewPost({ ...newPost, budget: e.target.value })
                  }
                  className="w-full p-2 border rounded-md"
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="mt-4 flex justify-between">
              <button
                onClick={handleSavePost}
                className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
              >
                {postLoading ? "Posting..." : "Post"}
              </button>
              <button
                onClick={() => setModalOpen(false)}
                className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Feed */}
      <div className="max-w-4xl mx-auto space-y-4">
        {posts.map((post) => (
          <div
            key={post.id}
            className="bg-white shadow-md rounded-lg p-4 border border-gray-200"
          >
            <div className="flex flex-row mb-1">
              <Avatar size="md" name={post.author} />
              <Text className="mt-2 ml-2" fontSize="lg" color="gray.600">
                {post.author}
              </Text>
            </div>
            <p className="text-lg font-medium text-gray-800 mb-2">
              {post.description}
            </p>
            <div className="text-sm text-gray-600 space-y-1">
              <p>
                <span className="font-semibold">Location:</span> {post.location}
              </p>
              <p>
                <span className="font-semibold">Make:</span> {post.make}
              </p>
              <p>
                <span className="font-semibold">Model:</span> {post.model}
              </p>
              <p>
                <span className="font-semibold">City:</span> {post.city}
              </p>
              <p>
                <span className="font-semibold">Budget:</span> {post.budget}
              </p>
              <p className="text-xs text-gray-500">
                Updated:{" "}
                {post.timestamp?.toDate?.().toLocaleString() || "Just now"}
              </p>
            </div>

            {/* Likes and Comments Count */}
            <div className="mt-4 flex justify-between text-sm text-gray-600">
              <span>{post.likes} Likes</span>
              <span>{post.comments?.length || 0} Comments</span>
            </div>

            {/* Action Buttons */}
            <div className="mt-2 flex space-x-4">
              <button
                onClick={() => handleToggleLike(post.id)}
                className={`${
                  likedPosts[post.id]
                    ? "text-blue-800 font-bold cursor-not-allowed"
                    : "text-blue-600 hover:underline"
                }`}
                disabled={likedPosts[post.id]}
              >
                {likedPosts[post.id] ? "Liked" : "Like"}
              </button>
              <button
                onClick={() => toggleCommentsVisibility(post.id)}
                className="text-blue-600 hover:underline"
              >
                {post.showComments ? "Hide Comments" : "Comment"}
              </button>
            </div>

            {/* Comments Section */}
            {post.showComments && (
              <div className="mt-4">
                <h3 className="text-md font-semibold mb-2">Comments</h3>
                <ul className="space-y-3 mt-4">
                  {post.comments?.map((comment, index) => (
                    <li
                      key={index}
                      className="text-sm bg-gray-50 p-2 rounded-md shadow-sm"
                    >
                      <div>
                        <p className="text-blue-700 font-medium">
                          {comment.author}
                        </p>
                      </div>
                      {comment.imageUrl && (
                        <img
                          src={comment.imageUrl}
                          alt="Comment Attachment"
                          className="w-16 h-16 object-cover rounded-md"
                        />
                      )}
                      <div>
                        <p>{comment.text}</p>
                        <p className="text-xs text-gray-500">
                          {new Date(comment.timestamp).toLocaleString()}
                        </p>
                      </div>
                      <div className="mt-2 flex space-x-4">
                        <button
                          onClick={() =>
                            handleToggleLikeComment(post.id, index)
                          }
                          className={`${
                            likedComments[`${post.id}_${index}`]
                              ? "text-blue-800 font-bold"
                              : "text-blue-600 hover:underline"
                          }`}
                        >
                          {likedComments[`${post.id}_${index}`]
                            ? "Liked"
                            : "Like"}
                        </button>
                        <button
                          onClick={() => {
                            setShowReplyInput(true);
                          }}
                          className="text-green-600 hover:underline text-sm"
                        >
                          Reply
                        </button>
                      </div>
                      {showReplyInput && (
                        <div className="mt-2">
                          <input
                            type="text"
                            placeholder="Write a reply..."
                            onKeyDown={(e) => {
                              if (
                                e.key === "Enter" &&
                                e.target.value.trim() !== ""
                              ) {
                                handleAddReply(
                                  post.id,
                                  index,
                                  e.target.value.trim()
                                );
                                e.target.value = "";
                              }
                            }}
                            className="w-full p-2 border rounded-md"
                          />
                        </div>
                      )}
                      <ul className="mt-2 pl-4 space-y-2">
                        {comment.replies?.map((reply, replyIndex) => (
                          <li
                            key={replyIndex}
                            className="text-sm bg-white p-2 rounded-md shadow-sm"
                          >
                            <p className="text-blue-700 font-medium">
                              {reply.author}
                            </p>
                            <p>{reply.text}</p>
                            <p className="text-xs text-gray-500">
                              {new Date(reply.timestamp).toLocaleString()}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                {/* Add Comment Form */}
                <div className="mt-4">
                  <textarea
                    placeholder="Add a comment..."
                    className="w-full p-2 border rounded-md mb-2"
                    rows={2}
                    value={commentInput[post.id]?.text || ""}
                    onChange={(e) =>
                      setCommentInput({
                        ...commentInput,
                        [post.id]: {
                          ...commentInput[post.id],
                          text: e.target.value,
                        },
                      })
                    }
                  />
                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, post.id)}
                      className="text-sm"
                    />
                    <button
                      onClick={() => handleAddComment(post.id)}
                      className="bg-green-600 text-white py-1 my-2 px-3 rounded-md hover:bg-green-700"
                    >
                      Post Comment
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuyerRequestsFeed;
